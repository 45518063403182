import React, { useEffect, useState, useCallback, memo } from 'react';
import GaugeChart from 'react-gauge-chart';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import isEqual from 'lodash/isEqual'; // lodash for deep comparison

const Gauge = memo(({ value, text, isMobile }) => {
  const arcsLength = [0.4, 0.3, 0.3]; // Arcs length for Green, Yellow, Red

  // Convert value to percentage
  const percent = value / 100; // Adjusted for 0-100 range

  return (
    <Grid item xs={4} key={text} sx={{ marginBottom: 0 }}>
      <GaugeChart
        id={`gauge-chart-${text}`}
        nrOfLevels={30}
        arcsLength={arcsLength}
        colors={['#B9B3BD', '#775C93', '#351B4A']}
        arcPadding={0.02}
        arcBordersColor="#A694A2"
        arcWidth={0.35}
        percent={percent}
        needleColor="#232323"
        needleBaseColor="#232323"
        hideText={true}
        style={{ maxWidth: '500px', margin: 'auto' }}
      />
      <Typography
        style={{ fontWeight: 'bold', marginTop: isMobile ? '2px' : '10px', textAlign: 'center', margin: 0 }}
      >
        {text}
      </Typography>
    </Grid>
  );
});

const Gauges = ({ posted, latlon, yearBuilt, onPostedChange, isSubmitted }) => {
  const [gaugeData, setGaugeData] = useState([
    { value: 0.0, text: 'Soil Erosion Risk' },
    { value: 0.0, text: 'Settlement Risk' },
    { value: 0.0, text: 'Slope Stability Risk' }
  ]);


  const fetchData = useCallback(async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}latlong/getSoilRisk/${latlon.lat}/${latlon.lng}/${yearBuilt}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();

      const newGaugeData = [
        { value: data.SoilErosionRisk, text: 'Soil Erosion Risk' },
        { value: data.SettlementRisk, text: 'Settlement Risk' },
        { value: data.SlopeInstabilityRisk, text: 'Slope Stability Risk' }
      ];

      // Only update state if data has changed
      if (!isEqual(newGaugeData, gaugeData)) {
        setGaugeData(newGaugeData);
      }

      onPostedChange(false);
    } catch (error) {
      console.error('Error fetching formatted data:', error);
    }
  }, [latlon, yearBuilt, gaugeData, onPostedChange]);

  useEffect(() => {
    if (posted && yearBuilt.length === 4 && isSubmitted) {
      fetchData();
    }
  }, [posted, yearBuilt, isSubmitted, fetchData]);

  return (
    <Grid container spacing={2} justifyContent="center">
      {gaugeData.map(({ value, text }) => (
        <Gauge key={text} value={value} text={text} />
      ))}
    </Grid>
  );
};

export default Gauges;
