import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, LinearProgress
} from '@mui/material';
import { createTheme, ThemeProvider, useMediaQuery, styled } from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const CustomLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  backgroundColor: '#d4d4d4',
  '& .MuiLinearProgress-barColorPrimary': {
    backgroundColor: '#351B4A',
  },
  '& .MuiLinearProgress-colorPrimary': {
    backgroundColor: '#d4d4d4',
  },
});

const DataSummary = ({ latlon, yearBuilt, posted, isSubmitted, onPostedChange, fetching, errorMessage, setFetching }) => {
  const [soilAttributes, setSoilAttributes] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [error, setError] = useState('');
  const [loadProgress, setLoadProgress] = useState(0);

  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchSoilAttributes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}latlong/properties/${latlon.lat}/${latlon.lng}/${yearBuilt}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Failed to fetch data');
        }
        const data = await response.json();
        const soilProperties = data.soilProperties?.[0]?.soilData?.[0]?.SoilAttributes;
        setSoilAttributes(soilProperties);
        setSummaryData(data);
        onPostedChange(false);
      } catch (error) {
        console.error('Error fetching soil attributes:', error);
        setError(error.message);
        onPostedChange(false);
      } finally {
        setLoadProgress(100); // Assuming data fetch is completed here
        setFetching(false);
      }
    };

    if (posted && yearBuilt.length === 4 && isSubmitted) {
      setFetching(true);
      fetchSoilAttributes();
    }
  }, [latlon, yearBuilt, posted, isSubmitted, onPostedChange, setFetching]);

  useEffect(() => {
    let interval;

    if (fetching) {
      setLoadProgress(0); // Reset progress to 0 whenever fetching starts

      interval = setInterval(() => {
        setLoadProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500); // Adjust the interval time as needed
    }

    return () => clearInterval(interval);
  }, [fetching]);

  const depthRanges = [
    { label: '0-5', lower: 0, upper: 5 },
    { label: '5-15', lower: 5, upper: 15 },
    { label: '15-30', lower: 15, upper: 30 },
    { label: '30-60', lower: 30, upper: 60 },
    { label: '60-100', lower: 60, upper: 100 },
    { label: '100-200', lower: 100, upper: 200 },
  ];

  const getValueForDepth = (layers, range) => {
    const lower_cm = parseFloat(range.lower);
    const upper_cm = parseFloat(range.upper);

    const values = layers
      .filter(layer => {
        const layerLower = parseFloat(layer.UpperDepth_m) * 100;
        const layerUpper = parseFloat(layer.LowerDepth_m) * 100;
        return layerLower < upper_cm && layerUpper > lower_cm;
      })
      .map(layer => {
        const value = parseFloat(layer.Value);
        if (Number.isNaN(value)) {
          return '-';
        }
        // Check if the value is an integer
        if (Number.isInteger(value)) {
          return value.toString(); // Return as string but integer format
        }
        // Limit the value to two decimal places if it's a floating point
        return value.toFixed(2);
      });

    if (values.length > 0) {
      return values.join(', ');
    }

    return 'X'; // Default value if no layers match
  };

  if (errorMessage) {
    setFetching(false);
  }

  return (
    <ThemeProvider theme={theme}>
      {fetching && !errorMessage && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(255, 255, 255, 1)"
          p={3}
          borderRadius={8}
          width="100%"
        >
          <CustomLinearProgress variant="determinate" value={loadProgress} sx={{ width: '100%', mb: 1 }} />
          <Typography variant="body2" color="#351B4A" mb={1} fontWeight="bold">
            fetching data... {Math.round(loadProgress)}%
          </Typography>
        </Box>
      )}
      <Box width="100%">
        {!fetching && (
          <Typography mt={4} mb={4} variant="h6" fontWeight="bold" display="flex" justifyContent="center" alignItems="center" gutterBottom>
            Summary of data available for the address:
          </Typography>
        )}

        <Typography>
          Location: {summaryData?.lat && summaryData?.lng ? `${summaryData.lat.toFixed(4)}°, ${summaryData.lng.toFixed(4)}°` : '-'}
        </Typography>
        <Typography>Built Year: {summaryData?.buildYear || '-'}</Typography>
        <Typography>
          Slope: {summaryData?.landscape.find(item => item.Name === 'Relief_slopepct1s')?.value ? `${summaryData.landscape.find(item => item.Name === 'Relief_slopepct1s').value.toFixed(1)}%` : '-'}
        </Typography>
        <Typography>
          Median 300m radius: {summaryData?.landscape.find(item => item.Name === 'Relief_focalmedian300m_1s')?.value ? `${summaryData.landscape.find(item => item.Name === 'Relief_focalmedian300m_1s').value.toFixed(1)}%` : '-'}
        </Typography>
        <Typography>
          Expected climate change (temperature): {summaryData?.expectedClimateChange?.temperature ? `${summaryData.expectedClimateChange.temperature.toFixed(1)}°C` : '-'}
        </Typography>
        <Typography>
          Expected climate change (rainfall): {summaryData?.expectedClimateChange?.rainfall ? `${summaryData.expectedClimateChange.rainfall.toFixed(1)} mm` : '-'}
        </Typography>
        <Typography sx={{ mt: 4, fontWeight: 'bold' }}>Soil information:</Typography>
        <TableContainer component={Paper} sx={{ marginTop: 2, border: '2px solid #302241' }}>
          <Table sx={{ minWidth: '100%' }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#302241' }}>
                <TableCell sx={{ color: 'white', fontSize: isMobile ? 'medium' : 'large', textAlign: 'center' }}>Depth [cm]</TableCell>
                {depthRanges.map((range, index) => (
                  <TableCell key={index} sx={{ color: 'white', textAlign: 'center', fontSize: isMobile ? 'small' : 'large' }}>
                    {range.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {['Water Capacity [%]', 'Bulk density [g/cm³]', 'Clay [%]', 'Silt [%]', 'Sand [%]', 'Depth of Regolith [m]', 'Depth of soil [m]'].map((row, index) => (
                <TableRow key={index} sx={{ borderBottom: '2px solid #351B4A', backgroundColor: index % 2 === 0 ? '#B9B3BD' : '#ffffff' }}>
                  <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>{row}</TableCell>
                  {soilAttributes ? (
                    depthRanges.map((range, idx) => (
                      <TableCell key={idx} sx={{ textAlign: 'center', fontSize: isMobile ? 'small' : 'large' }}>
                        {row.includes('Regolith') && getValueForDepth(soilAttributes.find(attr => attr.Attribute === 'DER')?.SoilLayers || [], range)}
                        {row.includes('Water Capacity') && getValueForDepth(soilAttributes.find(attr => attr.Attribute === 'AWC')?.SoilLayers || [], range)}
                        {row.includes('Bulk density') && getValueForDepth(soilAttributes.find(attr => attr.Attribute === 'BDW')?.SoilLayers || [], range)}
                        {row.includes('Clay') && getValueForDepth(soilAttributes.find(attr => attr.Attribute === 'CLY')?.SoilLayers || [], range)}
                        {row.includes('Silt') && getValueForDepth(soilAttributes.find(attr => attr.Attribute === 'SLT')?.SoilLayers || [], range)}
                        {row.includes('Sand') && getValueForDepth(soilAttributes.find(attr => attr.Attribute === 'SND')?.SoilLayers || [], range)}
                        {row.includes('Depth of soil') && getValueForDepth(soilAttributes.find(attr => attr.Attribute === 'DES')?.SoilLayers || [], range)}
                      </TableCell>
                    ))
                  ) : (
                    depthRanges.map((_, idx) => (
                      <TableCell key={idx} sx={{ textAlign: 'center', fontSize: isMobile ? 'small' : 'large' }}>X</TableCell>
                    ))
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ThemeProvider>
  );
};

export default DataSummary;
