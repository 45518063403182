import { useState } from 'react';

const UserDashboard = () => {
  const [address, setAddress] = useState('');
  const [yearBuilt, setYearBuilt] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [latLon, setLatLon] = useState(null);
  const [fetching, setFetching] = useState(false);

  const validateAddress = (address) => {
    const validStates = ['ACT', 'NSW', 'NT', 'SA', 'TAS', 'VIC', 'WA', 'QLD'];
    const normalizedAddress = address.toUpperCase();

    const containsAustralia = normalizedAddress.includes('AUSTRALIA');
    const containsValidState = validStates.some(state => normalizedAddress.includes(state));

    if (!containsAustralia) {
      return 'Address must contain "Australia".';
    }
    if (!containsValidState) {
      return 'Address must contain a valid Australian state abbreviation.';
    }
    return '';
  };

  const handleYearBuiltSubmit = async () => {
    setFetching(true);
    setErrorMessage('');

    if (!address.trim()) {
      setErrorMessage('Please enter an address.');
      setFetching(false);
      return;
    }

    const addressError = validateAddress(address);
    if (addressError) {
      setErrorMessage(addressError);
      setFetching(false);
      return;
    }
    if (!yearBuilt.trim()) {
      setErrorMessage('Please enter the year built.');
      setFetching(false);
      return;
    }

    const year = yearBuilt.trim(); // Trim any leading or trailing whitespace

    if (!/^\d+$/.test(year)) {
      setErrorMessage('Please enter a valid year.');
      setFetching(false);
      return;
    }

    const yearNumber = parseInt(year);
    const currentYear = new Date().getFullYear();

    if (isNaN(yearNumber)) {
      setErrorMessage('Please enter a valid year.');
      setFetching(false);
      return;
    }

    if (yearNumber > currentYear) {
      setYearBuilt(currentYear.toString());
    } else if (yearNumber < 1950) {
      setYearBuilt('1950');
    }

    try {
      const apiKey = 'AIzaSyCBsgXrnYEec2xwc0b0rmAt\_c8rZj8TUDY';
      const encodedAddress = encodeURIComponent(address);
      const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`;

      const geoResponse = await fetch(url);
      const geoData = await geoResponse.json();
     
        
      if (geoData.status === 'OK' && geoData.results.length > 0) {
        const { lat, lng } = geoData.results[0].geometry.location;
        const country = geoData.results[0].address_components.find(component => component.types.includes('country')).long_name;
        if (country === 'Australia' && lat >= -44 && lat <= -9 && lng >= 112 && lng <= 154) {
          setLatLon({ lat, lng });
          setErrorMessage('');
        } else {
          setErrorMessage('The coordinates are not within the Australian territory.');
        }
      } else {
        setErrorMessage('Failed to fetch coordinates. Please check the address.');
      }
      setFetching(false);
    } catch (error) {
      console.error('Error fetching geocode data:', error);
      setErrorMessage('Failed to fetch coordinates. Please try again.');
      setFetching(false);
    }
  };

  

  const handleYearBuiltChange = (e) => {
    setYearBuilt(e.target.value);
    if(e.target.value.length===4){
      const year = e.target.value.trim();
      const currentYear = new Date().getFullYear();

      if (/^\d+$/.test(year)) {
        const yearNumber = parseInt(year);

        if (yearNumber > currentYear) {
           setYearBuilt(currentYear.toString());
        } else if (yearNumber < 1950) {
          setYearBuilt('1950');
        } else {
          setYearBuilt(year);
        }
      } else {
        setYearBuilt('');
      }

    }
    
  };

  return {
    address,
    yearBuilt,
    errorMessage,
    latLon,
    fetching,
    setFetching,
    setAddress,
    setYearBuilt,
    setLatLon,
    setErrorMessage,
    handleYearBuiltChange,
    handleYearBuiltSubmit,
  };
};

export default UserDashboard;
