import { useEffect, useState } from 'react';

const LatLongProperties = ({ latLon, yearBuilt, onHasPostedChange }) => {
  const [localLatLon, setLocalLatLon] = useState(latLon);
  const [shouldPost, setShouldPost] = useState(false);

  useEffect(() => {
    if (localLatLon && localLatLon.lat !== null && localLatLon.lng !== null && yearBuilt !== null) {
      setShouldPost(true);
    }
  }, [localLatLon, yearBuilt]);

  useEffect(() => {
    if (shouldPost) {
      const apiUrl = `${process.env.REACT_APP_API_URL}latlong/properties`;

      const postLatLon = async () => {
        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ lat: localLatLon.lat, lng: localLatLon.lng, buildYear: yearBuilt }),
          });

          if (response.ok) {
            onHasPostedChange(true); // Notify the parent component
            setShouldPost(false);
            setLocalLatLon({ lat: null, lng: null }); // Reset localLatLon
          } else {
            console.error('Failed to post data, status:', response.status);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

      postLatLon();
    }
  }, [shouldPost]);

  useEffect(() => {
    // Update localLatLon whenever latLon prop changes
    setLocalLatLon(latLon);
  }, [latLon]);

  return null; // Return null or some JSX if needed
};

export default LatLongProperties;
