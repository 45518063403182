import React, { useState, useRef } from 'react';
import {
  Container,
  Typography,
  TextField,
  Box,
  Button,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import UserDashboard from '../components/UserDasboard';
import LatLongProperties from '../components/LatLongProperties';
import Gauges from '../components/Gauges';
import DataSummary from '../components/SoilInformation';
import { useTheme } from '@mui/material/styles';
import ReCAPTCHA from 'react-google-recaptcha';

const DashboardUI = () => {
  const {
    address,
    setAddress,
    yearBuilt,
    errorMessage,
    latLon,
    fetching,
    setFetching,
    setErrorMessage,
    handleYearBuiltChange,
    handleYearBuiltSubmit,
  } = UserDashboard();

  const [posted, setPosted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [recaptchaV2Checked, setRecaptchaV2Checked] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const isMobile = useMediaQuery('(max-width:600px)');

  const recaptchaRef = useRef(null);

  const theme = useTheme();

  const fetchPredictions = async (userInput) => {
    const endpoint = `${process.env.REACT_APP_API_URL}api/autocomplete?input=${userInput}`;

    try {
      const response = await fetch(endpoint);
      const data = await response.json();
      setPredictions(data.predictions);
    } catch (error) {
      console.error('Error fetching predictions:', error);
      setPredictions([]);
    }
  };

  const handleInputChange = (e) => {
    const userInput = e.target.value;
    setAddress(userInput);
    if (userInput.length > 0) {
      fetchPredictions(userInput);
    } else {
      setPredictions([]);
    }
  };

  const handlePredictionClick = (description) => {
    setAddress(description);
    setPredictions([]);
  };


  const handleFetch = async () => {
    if (!recaptchaV2Checked) {
      setErrorMessage('Please complete the reCAPTCHA challenge.');
      return;
    }

    try {
      await handleYearBuiltSubmit();
      setFetching(true);
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage('Check your address format is correct. Please try again.');
      setFetching(false);
    } finally {
      recaptchaRef.current.reset();
      setRecaptchaV2Checked(false);
    }
  };

  const handleHasPostedChange = (hasPosted) => {
    setPosted(hasPosted);
    if (!hasPosted) {
      setFetching(false);
    }
  };

  return (
    <Container
      sx={{
        width: '100%',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '10px',
        position: 'relative',
      }}
    >
      <Box
        p={3}
        borderRadius={10}
        textAlign="center"
        width="100%"
        maxWidth={isMobile ? '100%' : '800px'}
        bgcolor="rgba(255, 255, 255, 0.7)"
      >
        {isMobile && (
          <Box display="flex" justifyContent="center" mt={2}>
            <ReCAPTCHA
              sitekey="6Ldl5_gpAAAAALJ4PBdY8to4nLe4fRCqoLeNlhAA"
              onChange={(token) => setRecaptchaV2Checked(!!token)}
              ref={recaptchaRef}
              style={{ height: '100%', flex: 1 }}
            />
          </Box>
        )}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={2}
          flexDirection={isMobile ? 'column' : 'row'}
          sx={{ gap: isMobile ? '16px' : '24px', marginBottom: '24px' }}
        >
          <Box sx={{ position: 'relative'}}>
            <TextField
              id="autocomplete-input"
              placeholder="Enter an Australian address"
              label="Australian address"
              value={address}
              onChange={handleInputChange}
              sx={{
                flex: 2,
                height: '75px',
                width: '250px',
                '.MuiInputBase-root': { height: '100%' },
                '.MuiInputLabel-root': { mt: 1 },
              }}
            />
            {predictions.length > 0 && (
              <Paper sx={{ position: 'absolute', zIndex: 1, width: '100%' }}>
                <List>
                  {predictions.map((prediction) => (
                    <ListItem
                      button
                      key={prediction.place_id}
                      onClick={() => handlePredictionClick(prediction.description)}
                    >
                      <ListItemText primary={prediction.description} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            )}
          </Box>
          <TextField
            label="Built Year"
            variant="outlined"
            placeholder="Enter built year"
            value={yearBuilt}
            onChange={handleYearBuiltChange}
            sx={{
              flex: 1,
              height: '75px',
              '.MuiInputBase-root': { height: '100%' },
              '.MuiInputLabel-root': { mt: 1 },
            }}
          />
          {!isMobile && (
            <ReCAPTCHA
              sitekey="6Ldl5_gpAAAAALJ4PBdY8to4nLe4fRCqoLeNlhAA"
              onChange={(token) => setRecaptchaV2Checked(!!token)}
              ref={recaptchaRef}
              style={{
                height: '75px',
                display: 'flex',
                alignItems: 'center',
                width: '310px',
              }}
            />
          )}
        </Box>
        {errorMessage && (
          <Typography variant="body2" color="error" mt={2}>
            {errorMessage}
          </Typography>
        )}
        <Box display="flex" alignItems="center" mt={2} ml={isMobile ? 2 : 'none'}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#351B4A',
              color: 'white',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mr: 1,
              '&:hover': {
                backgroundColor: '#b9b3bd',
              },
            }}
            onClick={handleFetch}
          >
            Check
          </Button>
        </Box>
      </Box>

      <Box
        mt={2}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        width="100%"
        maxWidth={isMobile ? '100%' : '800px'}
      >
        <Gauges
          posted={posted}
          latlon={latLon}
          isSubmitted={isSubmitted}
          onPostedChange={handleHasPostedChange}
          yearBuilt={yearBuilt}
        />
      </Box>
      <Box width="100%" maxWidth={isMobile ? '100%' : '780px'}>
        <DataSummary
          latlon={latLon}
          yearBuilt={yearBuilt}
          posted={posted}
          fetching={fetching}
          setFetching={setFetching}
          errorMessage={errorMessage}
          isSubmitted={isSubmitted}
          onPostedChange={handleHasPostedChange}
        />
      </Box>
      {latLon && (
        <LatLongProperties
          latLon={latLon}
          yearBuilt={yearBuilt}
          onHasPostedChange={handleHasPostedChange}
        />
      )}
    </Container>
  );
};

export default DashboardUI;
